<template>
    <BaseSection
        class="base-section--full-bleed"
        :class="{
            'base-section--align-left': !prefiltered,
        }"
    >
        <template
            v-if="pending || !!$slots.title"
            #title
        >
            <template v-if="pending">
                <Skeletor />
            </template>
            <slot
                v-else
                name="title"
            />
        </template>

        <template
            v-if="pending || !!$slots.intro"
            #intro
        >
            <template v-if="pending">
                <Skeletor />
                <Skeletor />
            </template>
            <slot
                v-else
                name="intro"
            />
        </template>

        <div
            class="property-list"
            :class="{
                'property-list--prefiltered': prefiltered
            }"
        >
            <BaseFilters
                v-if="!prefiltered"
                class="property-list__filters"
                :active-filters="[activeFilter]"
                :items="cities"
                :pending="pending"
                @update:active-filter="activeFilter = $event"
                @update:clear-filters="activeFilter = null"
            >
                <template #all-filters>
                    {{ $t('all-locations') }}
                </template>
            </BaseFilters>

            <PropertyListSlider
                v-if="data?.propertiesEntries"
                :items="data?.propertiesEntries"
                :pending="pending || pendingFilters"
            />
            <!-- CLONED FILTERS FOR OVERLAY EFFECT -->
            <BaseFilters
                v-if="!prefiltered"
                class="property-list__filters property-list__filters--cloned"
                :active-filters="[activeFilter]"
                :items="cities"
                :pending="pending"
                @update:active-filter="activeFilter = $event"
                @update:clear-filters="activeFilter = null"
            >
                <template #all-filters>
                    {{ $t('all-locations') }}
                </template>
            </BaseFilters>
        </div>
    </BaseSection>
</template>

<script setup>
import { defineProps } from 'vue';
import propertyListQuery from '~/graphql/queries/propertyList.graphql';

const props = defineProps({
    pending: {
        type: Boolean,
        default: false
    },
    cities: {
        type: Array,
        default: () => []
    },
    fixedFilter: {
        type: [String, Number],
        default: null
    }
});

const prefiltered = computed(() => {
    return props.fixedFilter !== null;
});

const activeFilter = ref(null);
watch(() => props.fixedFilter, () => {
    activeFilter.value = props.fixedFilter;
}, { immediate: true });
const { variables } = useCraftGraphql();
variables.city = activeFilter;
delete variables.uri;

const { data, pending: pendingFilters } = await useLazyAsyncQuery({
    query: propertyListQuery,
    variables
});

const clearFilters = () => {
    activeFilter.value = null;
};
</script>

<style lang="less">
.property-list {
    // stylelint-disable-next-line length-zero-no-unit
    --filters-width: 0rem;

    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
    width: 100%;

    .base-slider {
        justify-content: flex-start;
    }

    @media @q-md-min {
        --filters-width: 15rem;

        gap: var(--spacing-lg);
        flex-direction: row;
    }
}

.property-list__filters {
    @media @q-md-min {
        position: absolute;
        z-index: 0;
        flex: 1 0 auto;

        width: var(--filters-width);
    }
}

.property-list__filters--cloned {
    @media @q-md-min {
        opacity: 0;
        z-index: 10;

        transition: opacity var(--transition-timing-medium) var(--transition-timingFunction-in-out);

        &:hover {
            opacity: 1;
        }
    }
}

.property-list--prefiltered {
    // stylelint-disable-next-line length-zero-no-unit
    --filters-width: 0rem;

    .base-slider {
        justify-content: center;
    }
}
</style>
